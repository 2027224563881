import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAcquireTokenMutation } from 'src/api';
import { useAuth } from 'src/contexts/AuthProvider';
import { useAuthErrors } from 'src/utils/apollo-client';

export function StealLogin() {
  const { logout } = useAuth();
  const [authErrors, resetAuthErrors] = useAuthErrors();

  const [stealLogin, { loading }] = useAcquireTokenMutation({
    variables: { stealLogin: true },
    onCompleted(data) {
      localStorage.setItem('loginToken', data.acquireLoginToken);

      if (authErrors.length > 0) {
        authErrors.forEach((retry) => retry(true));
        resetAuthErrors();
      }
    },
  });

  if (!authErrors.length) return;

  return (
    <Dialog open>
      <DialogTitle>Login-Konflikt</DialogTitle>
      <DialogContent>
        <DialogContentText>Es ist bereits jemand für diesen Account eingeloggt.</DialogContentText>
        <DialogContentText sx={{ mt: 3 }}>
          Möchten Sie diese Person ausloggen und fortfahren?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={logout}>Abbrechen</Button>
        <Button onClick={() => stealLogin()} loading={loading}>
          Sitzung übernehmen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
